//
// All our forms have a personal-data-submission class, which processed
// by the script app/javascript/modules/_personal-data-submission.js
//
// This script in turn calls recaptchaSubmit() here below to submit the form.
// The recaptchaSubmit() function stores the form being processed in a global
// variable and then calls grecaptcha.execute() to trigger the reCAPTCHA.
//
// The reCAPTCHA should be configured to call the submitInvisibleRecaptchaExamsForm()
// function when the user has successfully completed the reCAPTCHA.
//
// The submitInvisibleRecaptchaForm() then submits the form that was stored
// in the global variable.
//
// All these gymnastics of storing the form in a global variable are necessary
// because we can have multiple forms on the same page, and we need to know
// which one to submit when the reCAPTCHA is completed.
//

window.submitInvisibleRecaptchaExamsForm = function() {
  if (window.recaptchaForm) {
    const form = window.recaptchaForm;
    window.recaptchaForm = null;
    return $(form).submit();
  }
  //console.error('submitInvisibleRecaptchaExamsForm: No form found to submit.');
};

window.recaptchaSubmit = function(form) {
  if (typeof grecaptcha !== 'undefined') {
    window.recaptchaForm = form;
    return grecaptcha.execute();
  }
  return $(form).submit();
};
