require('./jquery');
// First, import the Main Website application.js
import('val-loader!./load-csa-main-application');

// Then load CCSK-Specific deps
import Chart from 'chart.js';
import knockout from 'knockout/build/output/knockout-latest';
window.ko = knockout;

function importAll(r) { r.keys().forEach(r); }
importAll(require.context('./modules/', true, /\.(js|coffee)$/));

import './controllers';
